import React, { useEffect, useRef, useState } from 'react';
import * as mediasoupClient from 'mediasoup-client';
import { useSocket } from '../context/SocketProvider';
import tm_logo from './assets/images/tmSecure.png'
// const { ipcRenderer } = window.require('electron');

function getUID(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters[randomIndex];
  }
  
  return "BR_"+result;
}


function Producer() {
  const localVideo = useRef();
  const [device, setDevice] = useState(null); // State to manage the mediasoup device
  const [transport, setTransport] = useState(null); // State to manage the mediasoup transport
  const [userName, setUserName] = useState(getUID());
  const socket = useSocket();

  

  useEffect(() => {
    async function fetchSerialNumber() {
      const uid = getUID(6);
      try{
      
        const serial = await window.electronAPI.getSerialNumber();
        setUserName(serial || uid);
      
      }catch{
        
        setUserName(uid);
        console.log('Appliation opened in browser');
      
      }
    }
    

    
      
    fetchSerialNumber();

    
  }, []);

  useEffect(() => {
    async function start() {
      try {
        
        // Create and load the mediasoup device if not already done
        if (!device) {
          const newDevice = new mediasoupClient.Device();
          const rtpCapabilities = await new Promise(resolve => {
            socket.emit('getRtpCapabilities', resolve);
          });
          console.log('RTP capabilities fetched:', rtpCapabilities);

          await newDevice.load({ routerRtpCapabilities: rtpCapabilities });
          console.log('Device loaded with RTP capabilities.');
          setDevice(newDevice);
        }

        // Create and connect transport if not already done
        if (!transport) {
          
          const transportData = await createTransport();
          const newTransport = device.createSendTransport(transportData);

          newTransport.on('connect', ({ dtlsParameters }, callback) => {
            socket.emit('connectTransport', { dtlsParameters, id: userName, type:'producer' }, callback); //newTransport.id
          });

          newTransport.on('produce', ({ kind, rtpParameters }, callback) => {
            
            socket.emit('produce', { kind, rtpParameters, id:userName }, callback);
          });

          setTransport(newTransport);
        }

        // Obtain user media and start producing
        if (device && transport) {
          const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
          console.log('User media obtained.');
          // localVideo.current.srcObject = stream;
          stream.getTracks().forEach(track => transport.produce({ track }));
        }
      } catch (error) {
        console.error('Error during media handling:', error);
      }
    }

    // Function to create transport
    async function createTransport() {
      return new Promise(resolve => {
        socket.emit('createTransport', resolve);
      });
    }

    start();
  }, [socket, device, transport]); // Dependencies to ensure effect runs only when device or transport changes

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      
        <div style={{
          textAlign: 'center',
          color: 'white',
        }}>
          <img src={tm_logo} alt="TeamLink Secure Logo" style={{ width: '150px', marginBottom: '20px' }} />
          <h1>TeamLink Secure</h1>
        </div>
      
    </div>
  );
}


export default Producer;
