
import React, { useEffect, useRef, useState } from 'react';
// import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';
import { useSocket } from '../context/SocketProvider';

// const socket = io('http://localhost:5000');

function Consumer() {
  const [device, setDevice] = useState(null);
  const [transport, setTransport] = useState(null);
  const [consumer, setConsumer] = useState(null);
  const [videoStarted, setVideoStarted] = useState(false); // State to manage video playback
  const [producerArray, setProducerArray] = useState([]);
  const [targetUser, setTargetUser] = useState("null");
  const remoteVideo = useRef();

  const socket = useSocket();
  

  useEffect(() => {
    async function initialize() {
      try {
        console.log('Fetching RTP capabilities...');
        const rtpCapabilities = await new Promise(resolve => {
          socket.emit('getRtpCapabilities', resolve);
        });
        // console.log('RTP capabilities fetched:', rtpCapabilities);

        const device = new mediasoupClient.Device();
        await device.load({ routerRtpCapabilities: rtpCapabilities });
        
        setDevice(device);
        
        console.log('Device loaded with RTP capabilities.');

        const transportData = await createTransport();
        const recvTransport = device.createRecvTransport(transportData);

        setTransport(recvTransport);
        console.log('Receive transport created.');

        recvTransport.on('connect', ({ dtlsParameters }, callback) => {
          console.log('Transport connect event received.');
          socket.emit('connectTransport', { dtlsParameters, id: transport.id, type: 'consumer' }, (response) => {
            
            if (response.error) {
              
              console.error('Failed to connect transport:', response.error);
              callback(new Error(response.error));
            
            } else {
              console.log('Transport connected successfully');
              
              if (response.data) {
                console.log('Received array of strings:', response.data);
                setProducerArray(response.data);
              }
        
              callback(); // Signal success

              
            }
          });
        });

        recvTransport.on('connectionstatechange', (state) => {
          console.log('Transport connection state:', state);
          if (state === 'connected') {
            console.log('Transport connected.');
          }
        });

        const consumeResponse = await startConsuming(device.rtpCapabilities);

            if (consumeResponse.data){
              setProducerArray(consumeResponse.data);
            }
            if (consumeResponse.error) {
              throw new Error(consumeResponse.error);
            }

            console.log(`Consume response for ${targetUser}`, consumeResponse);

            const consumerInstance = await recvTransport.consume({
              id: consumeResponse.id,
              producerId: consumeResponse.producerId,
              kind: consumeResponse.kind,
              rtpParameters: consumeResponse.rtpParameters
            });

            setConsumer(consumerInstance);

            // Setting the video source
            if (remoteVideo.current) {
              const mediaStream = new MediaStream([consumerInstance.track]);
              remoteVideo.current.srcObject = mediaStream;
              // Waiting for user interaction before playing
              if (videoStarted) {
                remoteVideo.current.play().catch(error => console.error('Play error:', error));
              }
            } else {
              console.error('Video element not found.');
            }

            consumerInstance.track.addEventListener('ended', () => {
              console.log('Track ended.');
            });

            consumerInstance.track.addEventListener('mute', () => {
              console.log('Track muted.');
            });

            consumerInstance.track.addEventListener('unmute', () => {
              console.log('Track unmuted.');
            });
          

      } catch (error) {
        console.error('Error during media handling:', error);
      }
    }

    async function createTransport() {
      return new Promise((resolve, reject) => {
        socket.emit('createTransport', (response) => {
          if (response.error) {
            return reject(new Error(response.error));
          }
          resolve(response);
        });
      });
    }

    async function startConsuming(rtpCaps) {
      return new Promise((resolve, reject) => {
        socket.emit('consume', { rtpCapabilities: rtpCaps, producerId: targetUser }, (response) => {
          if (response.error) {
            return reject(new Error(response.error));
          }
          resolve(response);
        });
      });
    }

    initialize();
  }, [videoStarted, socket, targetUser]);

  function handleClick(name) {
    console.log(`${name} clicked`);
    setTargetUser(name);
  }
  return (
    <div>
      <button onClick={() => setVideoStarted(true)}>Start Stream</button>
      {producerArray.map((name, index) => (
        <button key={index} onClick={() => handleClick(name)}>
          {name}
        </button>
      ))}
      <video ref={remoteVideo} autoPlay playsInline height={"720px"} width={"1280px"} />
    </div>
  );
}

export default Consumer;
