
import './App.css';
import Consumer from "./components/Consumer";
import Producer from "./components/Producer";
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <>
    <Routes>
        <Route path='/' element={<Consumer />}/>
        <Route path='/user' element={<Producer />} />
    </Routes>
    </>
    
  );
}

export default App;
