import React, {createContext, useMemo, useContext} from "react";
import { io } from "socket.io-client"

// const server_url = "http://localhost:4000";
const server_url = "https://stream.vmachine.com";

const SocketContext = createContext(null);

export const useSocket = () =>{
    const socket = useContext(SocketContext);
    return socket
}

export const SocketProvider = (props) => {
    const socket = useMemo(() => io(server_url), []);

    return(
        <SocketContext.Provider value={socket}>
            {props.children}
        </SocketContext.Provider>
    );
}